import React from 'react';
import { Layout, Localization, Typography } from 'connex-cds';
import styled from 'styled-components';
import style from './style';
import roundedToCents from '../common/util/round-to-cents';
import { useGetCompanyAdmin } from '../../../../../../../../../query-hooks/company';
const { Container } = Layout;
const { Subtitle } = Typography;
const { Translate, useCurrency } = Localization;
const Styled = styled.div`
  ${style}
`;
export const Summary = ({ show = false, products = [], pricing = [], paymentAmount = 0, paymentMethod }) => {
  const { getCurrency } = useCurrency();
  const companyAdminSetup = useGetCompanyAdmin();

  const currencyString = companyAdminSetup?.data?.currency?.code || 'USD';

  const subtotal = React.useMemo(() => {
    return products.reduce((acum, item) => {
      const partialTotalItem = (item?.unitPrice || 0) * item.quantity?.value;
      return roundedToCents(Number(acum) + Number(partialTotalItem));
    }, 0);
  }, [products]);

  let salesTax = 0.0;
  let salesTaxRate = 0.0;

  const taxedProductsSubtotal = React.useMemo(() => {
    const taxedProducts = products.filter(
      p => p?.isTaxable || p?.item?.taxable === 'YES' || p?.priceSummary?.isTaxable
    );
    return taxedProducts.reduce((acum, item) => {
      const partialTotalItem = (item?.unitPrice || 0) * item.quantity?.value;
      return roundedToCents(Number(acum) + Number(partialTotalItem));
    }, 0);
  }, [products]);

  if (pricing?.taxPerProduct) {
    if (pricing?.taxRate) {
      salesTaxRate = pricing?.taxRate.total?.sales || 0.0;
      salesTax = roundedToCents(taxedProductsSubtotal * salesTaxRate);
    }
  }

  const total = roundedToCents(Number(subtotal) + Number(salesTax));

  const remainingBalance = roundedToCents(total - Number(paymentAmount));

  return (
    <Container flex={1} style={{ padding: '5px 10px' }}>
      <Styled>
        <Subtitle>
          <Translate stringId="costAndPaymentsSummary" data-testid="costAndPaymentsLabel" />
        </Subtitle>
        <div className="comment-section" style={{ padding: '5px' }}>
          <table width="100%">
            <tr style={{ paddingTop: '10%' }}>
              <th className="left-header">
                <Localization.Translate stringId="subtotal" data-testid="activity-header-text" />
              </th>
              <td>{getCurrency(subtotal, currencyString)}</td>
            </tr>
            <tr>
              <th className="left-header">
                <Localization.Translate stringId="tax" data-testid="activity-header-text" />
              </th>
              <td>{getCurrency(salesTax, currencyString)}</td>
            </tr>
            <tr>
              <th className="left-header">
                <Localization.Translate stringId="total" data-testid="activity-header-text" />
              </th>
              <td>{getCurrency(total, currencyString)}</td>
            </tr>
            {show && (
              <>
                <tr>
                  <th className="left-header">
                    <Localization.Translate stringId="paymentAmount" data-testid="activity-header-text" />
                  </th>
                  <td>{getCurrency(paymentAmount, currencyString)}</td>
                </tr>
                <tr>
                  <th className="left-header">
                    <Localization.Translate stringId="paymentMethod" data-testid="activity-header-text" />
                  </th>
                  <td>{paymentMethod}</td>
                </tr>
                <tr>
                  <th className="left-header">
                    <Localization.Translate stringId="remainingBalance" data-testid="activity-header-text" />
                  </th>
                  <td>{getCurrency(remainingBalance, currencyString)}</td>
                </tr>
              </>
            )}
          </table>
        </div>
      </Styled>
    </Container>
  );
};
