import React from 'react';
import { Core, Form, Localization, Typography } from 'connex-cds';
import styled from 'styled-components';
import cn from 'classnames';
import { useCompanySetup } from '../../../MasterDataProvider';
import { useTicketContext } from '../../../TicketContext';
import getProducts from '../../../getProducts';
import { Activity } from './activity/Activity';
import { Comments } from './comments/Comments';
import { Products } from './costAndPayments/products/Products';
import { Summary } from './costAndPayments/summary/Summary';
import { DynamicWaiver } from './dynamic-waiver/DynamicWaiver';
import { FinalizeSection } from './finalize-section/FinalizeSection';
import { Header } from './header/Header';
import { LogisticalInfo } from './logistical-info/LogisticalInfo';
import { PriorSignatures } from './prior-signatures/PriorSignatures';

const { useTranslateMessage } = Localization;

const Styled = styled.div`
  & > * {
    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  .header-acceptance-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;

    h3 {
      margin: 0;
    }
  }
`;

export const AcceptanceMobile = ({
  lineItems,
  handleBackClick,
  signed,
  flow,
  activeFormsAndWaivers,
  entitySupportedLanguages,
  isEnablePaymentAndCostWorkflows,
}) => {
  const { finalized, submitted, ticket, paymentMethod } = useTicketContext();

  const { values } = Form.useFormContext();
  const companySetup = useCompanySetup();
  const [isCod, setIsCod] = React.useState(false);
  const translateMessage = useTranslateMessage();

  React.useEffect(() => {
    if (paymentMethod?.isCOD && isEnablePaymentAndCostWorkflows) {
      setIsCod(true);
    }
  }, [paymentMethod]);

  const showDynamicWaiver = React.useMemo(() => {
    return !!companySetup?.data?.isDynamicWaiverEnabled && activeFormsAndWaivers?.length !== 0;
  }, [activeFormsAndWaivers?.length, companySetup?.data?.isDynamicWaiverEnabled]);

  const showPriorSignatures = React.useMemo(() => {
    return values?.damageWaiverSignature?.image || values?.waterReleaseSignature?.image;
  }, [values.damageWaiverSignature, values.waterReleaseSignature]);

  return (
    <Styled className={cn('acceptance-mobile')}>
      {(finalized || submitted) && (
        <div className={cn('header-acceptance-mobile')}>
          {signed || submitted ? null : (
            <Core.Button
              size="small"
              type="primary"
              stringId="back"
              data-testid="back-button"
              onClick={handleBackClick}
            />
          )}
          <Header flow={flow} signed={signed} entitySupportedLanguages={entitySupportedLanguages} />
        </div>
      )}
      {finalized && (
        <div className={cn('acceptance-mobile-finalize')}>
          <Typography.H3>
            <Localization.Translate stringId="finalize" data-testid="acceptance-title" />
          </Typography.H3>
        </div>
      )}
      <LogisticalInfo ticket={values} />
      <Activity mobileLayout lineItems={lineItems} />
      <Comments />
      {isCod && (
        <>
          <Products productsData={getProducts(ticket?.lineItems, values?.lineItems, submitted)} values={values} />
          <Summary
            show={true}
            products={getProducts(ticket?.lineItems, values?.lineItems, submitted)}
            pricing={values?.pricing}
            paymentAmount={values?.paymentAmount}
            paymentMethod={
              values?.isExpectedPaymentMethod ? paymentMethod?.name : translateMessage('otherPaymentMethod')
            }
          />
        </>
      )}
      {showPriorSignatures && <PriorSignatures />}
      {showDynamicWaiver && <DynamicWaiver />}
      <FinalizeSection ticketId={values.id} flow={flow} />
    </Styled>
  );
};
