import styled from '@emotion/styled';
import { css } from 'styled-components';

export const StyledTable = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas:
    'head-fixed'
    'body-scrollable'
    'foot-fixed';
  grid-template-rows: min-content auto min-content;
  .products-row {
    padding: 0 0.8rem 0 0.8rem;
    align-items: center;
    & > * {
      padding: 0 0.2rem;
    }
  }
`;

export const StyledHeader = styled.div`
  grid-area: head-fixed;
  .products-row {
    height: 2rem;
    font-weight: bold;
    border-bottom: 1px solid #abaaab;
    display: grid;
    grid-template-columns: auto;
    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      &:not(:first-child) {
        text-align: right;
      }
    }
  }
`;

export const StyledBody = styled.div`
  grid-area: body-scrollable;
  overflow-y: auto;
  scrollbar-color: var(--color-background-container) #ffffff;
  scrollbar-width: thin;

  .products-row {
    div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &.table-header {
      font-weight: bold;
      grid-template-columns: repeat(14, minmax(100px, 1fr));
      border-bottom: 2px solid #abaaab;
      text-align: left;
    }
    height: 2.5rem;
    display: inline-grid;
    grid-template-columns: repeat(14, minmax(100px, 1fr));
    border-bottom: 1px solid var(--color-border-container);
    div:not(:first-child) {
      text-align: right;
    }
  }
`;

export const StyledFooter = styled.div`
  grid-area: foot-fixed;
  .products-row {
    border-top: 2px solid #abaaab;
    height: 2rem;
  }
`;

export default css`
  transition: all 0.3s;
  border-radius: var(--radius-container);

  color: ${({ theme }) => {
    if (theme === 'dark') return '#F3F3F3;';
    return '#050505;';
  }};
  border: 1px solid var(--color-border-container);
  box-shadow: var(--boxShadow-container);
  overflow: hidden;
`;
